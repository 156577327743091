import { FC, ReactNode } from "react";

import { useTranslation } from "react-i18next";

import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { default as MinorIcon } from "@mui/icons-material/KeyboardArrowUp";
import { default as MajorIcon } from "@mui/icons-material/KeyboardDoubleArrowUp";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import {
  Avatar as MuiAvatar,
  Box,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  SvgIcon,
  Typography,
  Tooltip,
} from "@mui/material";

import styled from "@emotion/styled";

import { ColoredAvatarProps } from "components/notifications/types";

import { SEEN_ALARM_STATUSES } from "utils/constants";

import { AlarmSeverity } from "globalTypes";

import { AlarmProps } from "./types";

export const alarmSeverityIcon = (level: AlarmSeverity): ReactNode => {
  switch (level) {
    case AlarmSeverity.CRITICAL:
      return <ErrorOutlineIcon />;
    case AlarmSeverity.MAJOR:
      return <MajorIcon />;
    case AlarmSeverity.MINOR:
      return <MinorIcon />;
    case AlarmSeverity.WARNING:
      return <WarningAmberIcon />;
    default:
      return <QuestionMarkIcon />;
  }
};

export const alarmSeverityColours: Record<AlarmSeverity, string> = {
  CRITICAL: "error",
  MAJOR: "error",
  MINOR: "warning",
  WARNING: "warning",
  UNKNOWN: "info",
};

const AlarmCard: FC<Readonly<AlarmProps>> = ({ alarm, expandMessage }) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "component.alarm.expanded",
  });
  const isSeen = SEEN_ALARM_STATUSES.includes(alarm.status);

  return (
    <Box>
      <ListItem
        onClick={expandMessage}
        disableGutters
        disablePadding
        sx={{
          flexDirection: "column",
          ".Mui-selected": {
            backgroundColor: (theme) => theme.palette.background.default,
          },
          ".MuiButtonBase-root": {
            width: "100%",
          },
        }}
      >
        <ListItemButton selected={isSeen}>
          <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <Box sx={{ display: "flex" }}>
              <ListItemAvatar>
                <Tooltip title={alarm.severity}>
                  <Avatar
                    status={alarmSeverityColours[alarm.severity]}
                    seen={isSeen.toString()}
                  >
                    <SvgIcon fontSize="small">
                      {alarmSeverityIcon(alarm.severity)}
                    </SvgIcon>
                  </Avatar>
                </Tooltip>
              </ListItemAvatar>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography variant="subtitle2" color={"textPrimary"}>
                  {alarm.identifier.id}
                </Typography>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography variant="body2" color={"textSecondary"}>
                    {t("status") + ": " + alarm.status}
                  </Typography>
                  <Typography variant="body2" color={"textSecondary"}>
                    {t("trigger") + ": " + alarm.trigger.type}
                  </Typography>
                  <Typography variant="body2" color={"textSecondary"}>
                    {t("type") + ": " + alarm.type}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </ListItemButton>
      </ListItem>
    </Box>
  );
};

export default AlarmCard;

const Avatar = styled(MuiAvatar)<ColoredAvatarProps>(
  ({ theme, status, seen }) => ({
    backgroundColor: theme.palette[status].main,
    opacity: seen === "true" ? 0.5 : 1,
  }),
);
