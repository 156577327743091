import { AxiosResponse } from "axios";

import { clearJwtData } from "utils/jwt";

import { JwtData } from "types/jwt";

import axiosApiClient, { axiosAuthClient } from "./axios";

const AUTH_CODE = "code";
const SCOPE = "READ";
const CLIENT_ID = window._env_.REACT_APP_ID;
const CLIENT_SECRET = window._env_.REACT_APP_SECRET;
const AUTH_HOST = window._env_.REACT_APP_AUTH_HOST;

const enum GranyTypeEnum {
  AUTHORISATION_CODE = "authorization_code",
  REFRESH_TOKEN = "refresh_token",
}

const authenticationApi = {
  authenticateClient: () =>
    window.location.replace(
      `${axiosAuthClient.getUri()}/hive/oauth2/authorize?scope=${SCOPE}&response_type=${AUTH_CODE}&client_id=${CLIENT_ID}`,
    ),
  authorize: (code: string) =>
    axiosAuthClient.post(
      `hive/oauth2/token`,
      {
        grant_type: GranyTypeEnum.AUTHORISATION_CODE,
        scope: SCOPE,
        code,
      },
      {
        headers: {
          Authorization: `Basic ${window.btoa(
            `${CLIENT_ID}:${CLIENT_SECRET}`,
          )}`,
          "Content-Type": "application/x-www-form-urlencoded",
        },
      },
    ),
  refreshToken: (token?: string): Promise<AxiosResponse<JwtData, any>> =>
    axiosAuthClient.post(
      "/hive/oauth2/token",
      {
        grant_type: GranyTypeEnum.REFRESH_TOKEN,
        refresh_token: token,
      },
      {
        headers: {
          Authorization: `Basic ${window.btoa(
            `${CLIENT_ID}:${CLIENT_SECRET}`,
          )}`,
          "Content-Type": "application/x-www-form-urlencoded",
        },
      },
    ),
  logoutClient: () => axiosApiClient.post("/hive/logout"),
};

const logout = async () => {
  try {
    await authenticationApi.logoutClient();
  } catch (error) {
    console.error("Logout API call failed:", error);
  } finally {
    clearJwtData();
    window.location.href = `${AUTH_HOST}/hive/login`;
  }
};

export { AUTH_CODE, authenticationApi, logout };
