import React, { useState } from "react";

import { useTranslation } from "react-i18next";

import { Typography, Box, Button, List } from "@mui/material";

import styled from "@emotion/styled";

import AlarmCard from "components/alarms";
import Loader from "components/Loader";
import { SkeletonNotification } from "components/skeletons";

import { NotificationPayloadGet } from "domain/entities/notification";

import { Alarm } from "globalTypes";

import Notification from "./Notification";
import { AlertItemType } from "./types";

const AlertList = ({
  isLoading,
  newAlertCount,
  toggleAlertSeen,
  data,
  isExpanded,
  setIsExpanded,
  isFetching,
  listRef,
  alertItemType = AlertItemType.NOTIFICATION,
}: {
  isLoading: boolean;
  toggleAlertSeen: (alertId: string) => void;
  isExpanded: boolean;
  setIsExpanded?: (isExpanded: boolean) => void;
  data?: NotificationPayloadGet[] | Alarm[];
  newAlertCount?: number;
  isFetching: boolean;
  listRef?: React.RefObject<HTMLUListElement>;
  alertItemType?: AlertItemType;
}) => {
  const { t } = useTranslation();
  const [selectedAlert, setSelectedAlert] = useState("");

  const handleAlertExpand = (alertId: string) => {
    if (selectedAlert === alertId) {
      setSelectedAlert("");
      return;
    }
    setSelectedAlert(alertId);
  };

  const translationKey =
    alertItemType === AlertItemType.NOTIFICATION ? "notification" : "alarm";

  return (
    <>
      {newAlertCount !== undefined && (
        <AlertHeader p={2}>
          <Box sx={{ display: "flex", justifyContent: "center", gap: 2 }}>
            <Typography
              variant="subtitle1"
              color={isLoading ? "GrayText" : "textPrimary"}
            >
              {t(`component.${translationKey}.header`, {
                count: newAlertCount,
              })}
            </Typography>
            {(isLoading || isFetching) && <Loader size={10} />}
          </Box>
        </AlertHeader>
      )}
      <React.Fragment>
        <List
          disablePadding
          sx={{
            overflowY: "auto",
          }}
          ref={listRef}
        >
          {alertItemType === AlertItemType.NOTIFICATION
            ? data?.map((notification) => (
                <Notification
                  key={notification.identifier.id}
                  notification={notification as NotificationPayloadGet}
                  maxMessageLength={128}
                  expandMessage={() =>
                    handleAlertExpand(notification.identifier.id)
                  }
                  toggleSeen={() => toggleAlertSeen(notification.identifier.id)}
                  isNotificationOpen={
                    selectedAlert === notification.identifier.id
                  }
                />
              ))
            : data?.map((alarm) => (
                <AlarmCard
                  key={alarm.identifier.id}
                  alarm={alarm as Alarm}
                  expandMessage={() => handleAlertExpand(alarm.identifier.id)}
                  toggleSeen={() => toggleAlertSeen(alarm.identifier.id)}
                  isAlarmOpen={selectedAlert === alarm.identifier.id}
                />
              ))}

          {isFetching && <SkeletonNotification />}
        </List>
        {setIsExpanded && (
          <Box p={1} display="flex" justifyContent="center">
            <Button size="small" onClick={() => setIsExpanded(!isExpanded)}>
              {isExpanded
                ? t(`component.${translationKey}.showLess`)
                : t(`component.${translationKey}.showAll`)}
            </Button>
          </Box>
        )}
      </React.Fragment>
    </>
  );
};

const AlertHeader = styled(Box)`
  text-align: center;
  border-bottom: 1px solid ${(props) => props.theme.palette.divider};
`;

export default AlertList;
