import { AlarmResponse, AlarmResponseSearch } from "domain/entities/alarm";

import { AlarmStatusType } from "globalTypes";

import {
  BaseSearchParams,
  createApiQuery,
  createApiQueryInfinite,
  createSearchApiQuery,
} from "./queryUtils";
import { TelemetryEntityType } from "./telemetry";

export type AlarmTimeFilters = {
  startedFrom?: string;
  startedTo?: string;
  endedFrom?: string;
  endedTo?: string;
  acknowledgedFrom?: string;
  acknowledgedTo?: string;
  clearedFrom?: string;
  clearedTo?: string;
};

export interface SearchAlarmParams extends AlarmTimeFilters {
  projects?: string[];
  entityTypes?: string[];
  entities?: string[];
  type?: string;
  severities?: string[];
  statuses?: string[];
}

export interface SearchAlarmParamsLatest
  extends AlarmTimeFilters,
    BaseSearchParams {
  entities?: string[];
  entityTypes?: TelemetryEntityType[];
  severities?: string[];
  statuses?: AlarmStatusType[];
  type?: string;
  projects?: string[];
}

export const useAlarmSearch = createSearchApiQuery<
  AlarmResponseSearch,
  undefined,
  SearchAlarmParams
>("/hive/alarm");

export const useAlarmSearchInfinite = createApiQueryInfinite<
  AlarmResponseSearch,
  undefined,
  SearchAlarmParams & BaseSearchParams,
  number
>("/hive/alarm", {
  getNextPageParam: (lastPage) => {
    if (lastPage.page < lastPage.numberOfPages) {
      return lastPage.page + 1;
    }
  },
});

export const useLatestAlarmSearch = createApiQuery<
  AlarmResponseSearch,
  undefined,
  SearchAlarmParamsLatest
>("/hive/alarm");

export const useAlarmById = createApiQuery<AlarmResponse, { alarmId: string }>(
  "/hive/alarm/{alarmId}",
);
