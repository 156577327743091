import { ProjectOverviewResponse } from "domain/entities/overview";
import {
  ProjectPayloadPost,
  ProjectResponse,
  ProjectResponseSearch,
} from "domain/entities/project";

import axiosClient from "./axios";
import { createApiQuery, createSearchApiQuery } from "./queryUtils";

export const projectApi = {
  createProject: (data: ProjectPayloadPost) =>
    axiosClient.post<ProjectResponse>("/hive/project", data),
  updateProjectById: (projectId: string, data: ProjectPayloadPost) =>
    axiosClient.put<ProjectResponse>(`/hive/project/${projectId}`, data),
  deleteProjectById: (projectId: string) =>
    axiosClient.delete<ProjectResponse>(`/hive/project/${projectId}`),
  getProjectById: (projectId: string) =>
    axiosClient.get<ProjectResponse>(`/hive/project/${projectId}`),
};

export interface ProjectSearchQueryParams {
  name?: string;
  entitiesPerPage?: number;
  page?: number;
}

export const useProjectSearch = createSearchApiQuery<
  ProjectResponseSearch,
  undefined,
  ProjectSearchQueryParams
>("/hive/project");

export const useProjectById = createApiQuery<
  ProjectResponse,
  { projectId: string }
>("/hive/project/{projectId}");

export const useProjectOverviewById = createApiQuery<
  ProjectOverviewResponse,
  { projectId: string }
>("/hive/project/overview/{projectId}");
